<script setup lang="ts">
const props = defineProps<{ currentPrice: number; priceLimit: number }>();
const percent = computed(() => props.currentPrice / props.priceLimit * 100);
const amountLeft = computed(() => props.priceLimit - props.currentPrice);
</script>
<template>
  <div
    class="flex flex-col gap-3 bg-light-grey px-3 py-3 text-sm font-bold items-center"
  >
    <div class="flex gap-3 items-center whitespace-nowrap w-full">
      <p>€ 0,00</p>
      <span class="h-[3px] w-full bg-grey">
        <span
          class="h-[3px] bg-dark block"
          :style="{ width: (percent > 100 ? 100 : percent) + '%' }"
        />
      </span>
      <p>€ {{ priceLimit }},00</p>
    </div>
    <p class="text-sm text-grey text-center font-thin" v-if="currentPrice < priceLimit">
      Nur noch
      <span class="font-bold text-dark">
        € {{ amountLeft.toLocaleString('de-DE') }}
      </span>
      bis zum
      <span class="font-bold text-dark">kostenlosen Versand.</span>
    </p>
    <p v-else>Kostenloser Versand</p>
  </div>
</template>
